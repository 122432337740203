<template>
  <!--  <pre>{{ vv.$errors }}</pre>-->
  <div class="main">

    <!-- Loteria -->
    <div v-if="selectedRoleId===6 && bEdit">
      <div class="flex justify-content-center">
        <Dropdown v-model="content"
                  :options="loterias"
                  optionValue="code"
                  optionLabel="label"
                  placeholder="Seleccionar Lotería"
                  :onClick="refreshLoteria"
                  :onChange="setEntityId"
                  scrollHeight="400px"
                  class="drop"
                  :loading="loading"
                  :showClear="true"
                  :filter="true"
                  style="width: 100%; cursor: default"/>

      </div>
      <small v-if="vv.$errors" class="p-error">{{ vv.$errors[0] && vv.$errors[0].$message }}</small>
    </div>
  </div>

</template>

<script>
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";
import {required, helpers} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {ref} from "vue";

export default {
  name: "EntityFilter",
  props: {
    selectedRoleId: Number,
    entity_id: Number,
    selectedInput: {
      type: Number,
    },
    nonEmptyField: String,
    title: String,
    input: {
      type: Number,
    },
  },
  setup(props) {
    const content = ref(props.entity_id);//ref(this.content);//ref(88)
    const mandatoryField = 'Campo obligatorio';
    const rules = {
      content: {
        required: helpers.withMessage(mandatoryField, required),
        $autoDirty: true
      },
    }
    const vv = useVuelidate(rules, {content})
    return {content, vv}
  },
  mixins: [generalMixin],
  created() {
    this.mainService = new MainService();
    this.theDate = this.getYesterdayString()

    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };

    // this.entity_id = this.selectedInput;
  },
  data() {
    return {
      selectedLoteria: null,
      selectedEntityId: this.entity_id,
      loterias: null,

      loading: false,
      bEdit: true,
      identity: null,
      // entity_id: null,
    }
  },
  methods: {
    async refreshLoteria() {
      this.loading = true;
      this.selectedComercializador = null;
      this.loterias = await this.mainService.getLoteria({
      });
      this.loading = false;
    },
    setEntityId(event) {
      this.$emit('setEntityId', event)
    },
    getValue() {
      this.loading = true;
      // let result = null;
      let response = null;

      this.loading = false;
      return response;
    }
  },
}
</script>

<style scoped>
.main {
  /*padding: 10px;*/
  /*margin: 10px;*/
}

.field-title {
  color: var(--green-banklot);
  font-weight: bold;
  margin-bottom: 10px;
}

.content {
  margin-left: 10px;
}

.drop {
  height: 33px;
  width: 100%;
}
</style>